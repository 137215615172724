<template>
  <div class="home">
    <div class="bg" :class="{ 'bg-dark' : dark }" />

    <dropdown menu-title="Vue Dropdown Menu" :dark-mode=" dark ? 'force' : '' " class="menu">

      <section class="option">
        <button @click="sayHello">This is button for method</button>
        <span class="desc">This is Vue dropdown menu method that says hello for you.</span>
      </section>

      <section class="option">
        <a href="https://duckduckgo.com">This is basic a -link</a>
        <span class="desc">Clicking this takes you somewhere else.</span>
      </section>

      <section class="option">
        <router-link to="/about">This is Vue router link</router-link>
        <span class="desc">Clicking this takes you somewhere else.</span>
      </section>

    </dropdown>

    <section class="info" :class="{ 'info-dark' : dark }">
      Building a VueJS Dropdown Menu -demo by mmmrks — <span @click="dark = !dark">Switch to {{ dark ? 'light mode' : 'dark mode' }}</span>
    </section>

  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import dropdown from '@mmmrks/vue-dropdown-menu'

export default {
  name: 'Home',
  components: { dropdown },
  data() { return { dark: false } },
  mounted() {

    if( window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches )
      this.dark = true

  },
  methods: {

    sayHello() {
      alert("Well hello there 😘")
    }

  }
}
</script>

<style lang="scss" scoped>

.menu {

  @media screen and (max-width: 500px) {
    width: 85% !important;
    height: 60px !important;
    margin: 20px auto 0;
    font-size: 16px !important;

    * { font-size: 16px !important; }

  }

}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #f9f9f9;
}

.bg-dark {
  background: #232323;
}

.info {
  font-size: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20px;

  span {
    cursor: pointer;
    font-size: 16px;
  }

}

.info-dark {
  color: #808080;
}

</style>
